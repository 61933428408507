<template>
  <div justify="end">
    <v-dialog v-model="isVisibleShow" max-width="1200px">
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("Checkout") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="0">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("tracking_number") }}</td>
                            <td>{{ checkout.tracking_number }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("total") }}</td>
                            <td>{{ checkout.total }} DZD</td>
                          </tr>

                          <tr>
                            <td>{{ $t("began_at") }}</td>
                            <td>{{ checkout.began_at }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("ended_at") }}</td>
                            <td>{{ checkout.ended_at }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("hub") }}</td>
                            <td>
                              {{ checkout.hub ? checkout.hub.name : "_" }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="checkout.orders && checkout.orders.length > 0">
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("orders") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("tracking_number") }}
                            </th>

                            <th class="text-left">
                              {{ $t("total") }}
                            </th>
                            <th class="text-left">
                              {{ $t("created_at") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in checkout.orders" :key="item.id">
                            <td>{{ item.tracking_number }}</td>
                            <td>{{ item.total }} DZD</td>
                            <td>{{ item.created_at.substring(0, 10) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeForm()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      checkout: "checkouts/getCheckout",
    }),

    isVisibleShow: {
      get() {
        return this.$store.state.checkouts.isVisibleShow;
      },
      set(value) {
        this.$store.commit("checkouts/IS_VISIBLE_SHOW", value);
      },
    },
  },

  data() {
    return {};
  },
  methods: {
    closeForm() {
      this.$store.dispatch("checkouts/closeForm");
    },
  },
};
</script>
